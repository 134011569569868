body {
    background-color: rgb(235,164,0);
    font-size: 80%;
}

hr {
    margin: 4px 0;
}

.pleft, .pright, .pcontent, .pheader {
    background-color: rgb(133,31,0);
}

.pleft .pmenu {
    border-right: solid 3px lime;
}

.pright .pmenu {
    border-left: solid 3px lime;
}

.pmenu {
    color: lime;
    padding: 3px 5px;
}

.pmenu ul {
    padding-left: 1rem;
}

.menu-block {
    border-bottom: solid 3px lime;
}

.menu-block label {
    color: rgb(0,86,0);
}

.dropdown-menu {
    background-color: rgb(0,86,0);
    filter: drop-shadow(0 0 0.75rem lime);
    border: solid 3px lime;
    font-size: 90%;
    margin: 0;
}

.dropdown-menu .nav-link {
    margin-left: 8px;
    margin-right: 8px;
 }

.nav-link:hover {
  color: white;
}

.nav-link:focus {
    color: white;
}

.nav-item.show > a {
    color: white !important;
}

.navbar-toggler {
    background-color: #0d6efd;
}

.ppcontent {
    margin: 5px;
}

.ppcontent .card.bg-light a {
    color: green;
}

.ppage {
    color: white;
}

.ppage a {
    color: #00ff00;
}

.ppage a:hover, .ppage a:focus {
    color: white;
}

.pcontainer {
    background-color: rgb(0,86,0);
}

.child {
    background-color: rgb(181,41,10);
    border: 1px solid rgb(217, 94, 23);
    opacity: 0.75;
    padding: 3px;
    margin: 3px;
}

.child.invi {
    background-color: darkgrey;
}

.child:hover {
    opacity: 1;
    border: 1px solid white;
}

.child.detail {
    opacity: 1;
}

.child img {
    border: solid 1px white;
}

.child h6 a {
    color: lime;
}

.pagination a {
    color: rgb(0,86,0);
}

.pagination .active>.page-link, .page-link.active {
    background-color: rgb(0,86,0);
    border-color: white;
}

.page-item {
    background-color: rgb(0,86,0);
}

.col {
    padding: 0;
}

.pheader {
    border-bottom: solid 14px lime;
}

.adoption-offer {
}

.adoption-offer-image {

}

.footer {
    background-color: #ccffff;
    color: black;
    text-align: center;
    font-size: small;
}

.footer a {
    color: rgb(0,86,0);
    font-weight: bold;
}

.news img {
    border: solid 1px white;
}

.editor-toolbar, .editor-statusbar {
    background-color: white;
}

.editor-preview a {
    color: blue;
}

.editor-preview-side {
    color: black;
}

.ppcontent .nav-tabs {
    border-bottom: none;
}

.ppcontent .nav-tabs .nav-link {
    border-bottom: none;
    color: lime;
}

.ppcontent .nav-tabs .nav-link.active {
    color: lime;
    background-color: rgb(181, 41, 10);
}

.ppcontent .tab-content {
    border: solid 1px white;
    padding: 10px;
    background-color: rgb(181, 41, 10);
}

.ppcontent table a {
    color: limegreen;
}

.ppcontent table a:hover {
    color: black;
}

.ppcontent .accordion {
    background-color: rgb(181, 41, 10);
}


.ppcontent .accordion-body {
    background-color: rgb(217, 94, 23);
}

.ppcontent .accordion-body {
    color: white;
}

.ppcontent .accordion-button {
    padding: 12px;
}

.ppage .text-black a {
    color: green;
}

.ppage .text-black a:hover, .ppage a:focus {
    color: black;
}

.menu-block a:focus, .nav-item a:focus, .navbar-nav a:focus {
    color: #00ff00;
}
